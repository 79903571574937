import React, { useState, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import {
	// DataTableWidget,
	SaveAction,
	TextFieldWidgetSelectControllerHorizontal,
	TextFieldWidgetText,
	CardHeaderWidget,
	BreadCrumbs,
	SnackBarsComponent,
} from '../../../components';

import {
	Card,
	CardContent,
	useTheme,
	Snackbar,
	Typography,
} from '@material-ui/core';
// import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { BsRow, BsCol, BsContainer } from '../../../layouts/components';
import { Context } from '../../../context/Context';
import { AxiosContext } from '../../../context/AxiosContext';
// import viewsTexts from '../../common/viewsTexts.json';
import { makeStyles } from '@material-ui/styles';
// import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { SimpleTabs, TabPanel } from 'components/TabPanel';
import viewsTexts from '../../../common/viewsTexts.json';
//import productiveUnit from '../Options/productiveUnit.json';
import {
	alphabeticalSort,
	alterArrayAddLabels,
	alterArrayGetFields,
	formatRut,
} from '../../../helpers';
// import theme from 'theme';
import { FiltersContext } from '../../../context';
import { Checkbox, FormControlLabel } from '@material-ui/core';

const years = Array.from(
	{ length: 11 },
	(_, index) => new Date().getFullYear() - index
);
const useStyles = makeStyles((theme) => ({
	root: {
		color: theme.palette.tertiary.main,
		fontWeight: 500,
	},
	divider: {
		background: theme.palette.grey[400],
		marginTop: 10,
	},
	rootHelper: {
		fontSize: 17,
	},
	inputText: {
		fontSize: 26,
	},
}));

const PuAdd = (props) => {
	const { userName } = useContext(Context);

	// console.log(userId, userHuerto, userName, "<<<<---context")
	const { state: filterState, addFilter } = useContext(FiltersContext);

	const { userOrchard_filter } = filterState;

	// console.log('----', filterState);

	const axiosContext = useContext(AxiosContext);
	// axiosContext.authAxios //queries with token

	const [filterDataResponseState, setFilterDataResponseState] = useState(
		userOrchard_filter
	);

	const [loadingQueryState, setLoadingQueryState] = useState(false);
	const [successQueryState, setSuccessQueryState] = useState(false);

	const [loadingFiltersState, setLoadingFiltersState] = useState(false);
	const [filtersDataState, setFiltersDataState] = useState(null);
	const [seasonState, setSeasonState] = useState([]);
	const [rootStockDataState, setRootStockDataState] = useState([]);
	const [varietyDataState, setVarietyDataState] = useState([]);

	const [specieDataState, setSpecieDataState] = useState([]);
	const [clientDataState, setClientDataState] = useState([]);
	const [agroBusinessDataState, setAgroBusinessDataState] = useState([]);
	const [orchardsDataState, setOrchardsDataState] = useState([]);
	const [ccDataState, setCcDataState] = useState([]);
	const [selectedYears, setSelectedYears] = useState([]);
	const [showOtherCcState, setShowOtherCcState] = useState(false);

	const [errorState, setErrorState] = useState(false);

	const theme = useTheme();

	// console.log(theme)

	const classes = useStyles();

	const {
		handleSubmit,
		control,
		watch,
		reset,
		setValue,
		getValues,
		setError,
		// clearErrors,
		formState: { errors },
	} = useForm();

	// const watchRut = watch('rut');
	const watchClient = watch('client');
	const watchAgroBusiness = watch('agroBusiness');
	const watchOrchard = watch('orchard');
	const watchSpecie = watch('specie');
	const watchVariety = watch('variety');
	const watchRootStock = watch('rootStock');
	const watchCc = watch('cc');

	const handleYearChange = (year) => (event) => {
		if (event.target.checked) {
			setSelectedYears([...selectedYears, year]);
		} else {
			setSelectedYears(selectedYears.filter((y) => y !== year));
		}
	};

	const onSubmit = async (values) => {
		setLoadingQueryState(true);
		if (selectedYears.length === 0) {
			setError('season', {
				type: 'manual',
				message: 'Seleccione al menos un año.',
			});
			setLoadingQueryState(false);
			return;
		}

		let clientObj = clientDataState.find(
			(ele) => ele.value === values.client
		);

		let agroBusinessObj = agroBusinessDataState.find(
			(ele) => ele.value === values.agroBusiness
		);

		let orchardObj = orchardsDataState.find(
			(ele) => ele.value === values.orchard
		);

		let specieObj = specieDataState.find(
			(ele) => ele.value === values.specie
		);

		let varietyObj = varietyDataState.find(
			(ele) => ele.value === values.variety
		);

		let rootStockObj = rootStockDataState.find(
			(ele) => ele.value === values.rootStock
		);

		let ccObjLabel = values.cc_other
			? values.cc_other
			: ccDataState.find((ele) => ele.value === values.cc).cc;

		const query = {
			clientValue: clientObj.clientValue,
			client: clientObj.client,
			username: userName,
			agroBusinessValue: agroBusinessObj.agroBusinessValue,
			agroBusiness: agroBusinessObj.agroBusiness,
			orchardValue: orchardObj.orchardValue,
			orchard: orchardObj.orchard,
			cc: ccObjLabel,
			quarter: values.quarter,
			specieValue: specieObj.specieValue,
			specie: specieObj.specie,
			varietyValue: varietyObj.varietyValue,
			variety: varietyObj.variety,
			rootStockValue: rootStockObj.rootStockValue,
			rootStock: rootStockObj.rootStock,
			season: selectedYears.map((year) => year.toString()),
		};

		console.log(query, 'envia a create');
		try {
			const response = await axiosContext.authAxios.post(
				'v1/unitProductive/create',
				query
			);
			const bodyInfo = response.Client;

			if (response.status === 200) {
				// alert('listo');
				setSuccessQueryState(true);

				reset({
					agroBusinessValue: '',
					orchardValue: '',
					cc: '',
					quarter: '',
					specieValue: '',
					varietyValue: '',
					rootStockValue: '',
					season: [],
				});
			}

			setSelectedYears([]);

			setLoadingQueryState(false);
		} catch (err) {
			console.log('Error', err.request.status);
			if (err.request.status === 500) {
				setErrorState(true);
			}

			setLoadingQueryState(false);
		}
	};

	const { password } = getValues();

	useEffect(() => {
		if (watchClient) {
			const resultClient = clientDataState.find(
				(ele) => ele.value === watchClient
			);
			setAgroBusinessDataState(
				alphabeticalSort(
					alterArrayAddLabels(resultClient.agroBusinessData, [
						'agroBusinessValue',
						'agroBusiness',
					]),
					'agroBusiness'
				)
			);
		}
	}, [watchClient]);

	useEffect(() => {
		if (watchClient && watchAgroBusiness) {
			const resultAgrobusiness = agroBusinessDataState.find(
				(ele) => ele.value === watchAgroBusiness
			);

			if (resultAgrobusiness) {
				setOrchardsDataState(
					alphabeticalSort(
						alterArrayAddLabels(resultAgrobusiness.orchardData, [
							'orchardValue',
							'orchard',
						]),
						'orchard'
					)
				);
			} else {
				setOrchardsDataState([]);
			}
		} else {
			setOrchardsDataState([]);
		}
	}, [watchAgroBusiness, agroBusinessDataState]);

	useEffect(() => {
		if (watchOrchard && watchAgroBusiness) {
			const resultOrchard = orchardsDataState.find(
				(ele) => ele.value === watchOrchard
			);

			if (resultOrchard) {
				const ccOptions = alterArrayAddLabels(resultOrchard.ccData, [
					'ccValue',
					'cc',
				]);

				setCcDataState([
					...ccOptions,
					{ label: 'Registrar Nuevo CC', value: 'nuevo_cc' },
				]);
			} else {
				setCcDataState([]);
			}
		} else {
			setCcDataState([]);
		}
	}, [watchOrchard, orchardsDataState]);

	// Dentro del useEffect donde manejas el cambio de especie
	useEffect(() => {
		if (watchSpecie) {
			const resultSpecie = specieDataState.find(
				(ele) => ele.value === watchSpecie
			);
			setVarietyDataState(
				alphabeticalSort(
					alterArrayAddLabels(resultSpecie.varietyData, [
						'varietyValue',
						'variety',
					]),
					'variety'
				)
			);
		}
	}, [watchSpecie]);

	useEffect(() => {
		if (watchSpecie && watchVariety) {
			const resultVariety = varietyDataState.find(
				(ele) => ele.value === watchVariety
			);

			if (resultVariety) {
				setRootStockDataState(
					alphabeticalSort(
						alterArrayAddLabels(resultVariety.rootStockData, [
							'rootStockValue',
							'rootStock',
						]),
						'rootStock'
					)
				);
			} else {
				setRootStockDataState([]);
			}
		} else {
			setRootStockDataState([]);
		}
	}, [watchVariety, varietyDataState]);

	useEffect(() => {
		// si el valor es nuevo_cc
		if (watchCc && watchCc === 'nuevo_cc') {
			setShowOtherCcState(true);
		} else {
			setShowOtherCcState(false);
			setValue('cc_other', '');
		}
	}, [watchCc]);

	// endpoints
	useEffect(() => {
		getDataFiltersAsync();
	}, []);

	const getDataFiltersAsync = async () => {
		setLoadingFiltersState(true);
		try {
			let response = await axiosContext.authAxios.post(
				'v1/unitProductive/filter-create'
			);

			const { data, status } = response;

			if (status === 200) {
				setFiltersDataState(data);
			}
		} catch (error) {
			console.log(error);
			// setLoadingVarietyFilterState(false);
		} finally {
			setLoadingFiltersState(false);
		}
	};

	const handleCloseSnackBarsComponent = () => {
		setErrorState(false);
	};

	useEffect(() => {
		if (filtersDataState) {
			setClientDataState(
				alphabeticalSort(
					alterArrayAddLabels(filtersDataState.clientData, [
						'clientValue',
						'client',
					]),
					'client'
				)
			);
			setSpecieDataState(
				alterArrayAddLabels(filtersDataState.specieData, [
					'specieValue',
					'specie',
				])
			);
		}
	}, [filtersDataState]);

	return (
		<div className="form-style">
			<BsContainer fluid class="px-0 px-md-3">
				<BsRow class="no-gutters">
					<BsCol class="col-md-12">
						<Card elevation={0}>
							<CardHeaderWidget
								title={viewsTexts.titles.productiveUnit.title}
								subheader={<BreadCrumbs subTitle="" />}
							/>
							{/* <Divider /> */}
							<CardContent>
								<BsRow>
									<BsCol class="col-md-12">
										<form
											autoComplete="on"
											onSubmit={handleSubmit(onSubmit)}
										>
											<BsRow>
												<BsCol class="col-md-6">
													<TextFieldWidgetSelectControllerHorizontal
														options={
															clientDataState
														}
														titleOption="label"
														control={control}
														name="client"
														labeltext="Cliente"
														errors={errors.client}
														defaultValue=""
														loading={
															loadingFiltersState
														}
														req
														withDefault
														variant="outlined"
													/>
												</BsCol>
												<BsCol class="col-md-6">
													<TextFieldWidgetSelectControllerHorizontal
														options={
															agroBusinessDataState
														}
														titleOption="label"
														control={control}
														name="agroBusiness"
														labeltext="Agrícola"
														errors={
															errors.agroBusiness
														}
														multiple={false}
														defaultValue=""
														loading={
															loadingFiltersState
														}
														req
														withDefault
														variant="outlined"
													/>
												</BsCol>
											</BsRow>
											<BsRow>
												<BsCol class="col-md-6">
													<TextFieldWidgetSelectControllerHorizontal
														options={
															orchardsDataState
														}
														titleOption="label"
														control={control}
														name="orchard"
														labeltext="Huerto"
														errors={errors.orchard}
														multiple={false}
														defaultValue=""
														loading={
															loadingFiltersState
														}
														req
														withDefault
														variant="outlined"
													/>
												</BsCol>
												<BsCol class="col-md-6">
													<TextFieldWidgetSelectControllerHorizontal
														options={ccDataState}
														titleOption="label"
														control={control}
														name="cc"
														labeltext="Centro de costo"
														errors={errors.cc}
														multiple={false}
														defaultValue=""
														loading={
															loadingFiltersState
														}
														req
														withDefault
														variant="outlined"
													/>
													{showOtherCcState ? (
														<TextFieldWidgetText
															name="cc_other"
															labeltext="Nombre Nuevo CC"
															req
															defaultValue=""
															InputLabelProps={{
																shrink: true,
															}}
															minLength={1}
															maxLength={100}
															control={control}
															errors={
																errors.cc_other
															}
														/>
													) : null}
												</BsCol>
											</BsRow>
											<BsRow>
												<BsCol class="col-md-6">
													<TextFieldWidgetText
														name="quarter"
														labeltext={
															viewsTexts.forms
																.quarter
														}
														req
														defaultValue=""
														InputLabelProps={{
															shrink: true,
														}}
														minLength={1}
														maxLength={100}
														control={control}
														errors={errors.quarter}
														autoComplete="quarter"
													/>
												</BsCol>
												<BsCol class="col-md-6">
													<TextFieldWidgetSelectControllerHorizontal
														options={
															specieDataState
														}
														titleOption="label"
														control={control}
														name="specie"
														labeltext="Especie"
														errors={errors.specie}
														defaultValue=""
														loading={
															loadingFiltersState
														}
														req
														withDefault
														variant="outlined"
													/>
												</BsCol>
											</BsRow>
											<BsRow>
												<BsCol class="col-md-6">
													<TextFieldWidgetSelectControllerHorizontal
														options={
															varietyDataState
														}
														titleOption="label"
														control={control}
														name="variety"
														labeltext="Variedad"
														errors={errors.variety}
														defaultValue=""
														loading={
															loadingFiltersState
														}
														req
														withDefault
														variant="outlined"
													/>
												</BsCol>
												<BsCol class="col-md-6">
													<TextFieldWidgetSelectControllerHorizontal
														options={
															rootStockDataState
														}
														titleOption="label"
														control={control}
														name="rootStock"
														labeltext="Porta injerto"
														errors={
															errors.rootStock
														}
														multiple={false}
														defaultValue=""
														loading={
															loadingFiltersState
														}
														req
														withDefault
														variant="outlined"
													/>
												</BsCol>
											</BsRow>
											<BsRow>
												<BsCol class="col-md-12">
													<div
														style={{
															display: 'flex',
															alignItems:
																'center',
														}}
													>
														<div
															style={{
																marginRight:
																	'16px',
																fontSize:
																	'0.85rem',
																width: '165px',
															}}
														>
															Temporada
														</div>
														<div
															style={{
																marginLeft: 10,
															}}
														>
															{years.map(
																(year) => (
																	<FormControlLabel
																		key={
																			year
																		}
																		control={
																			<Checkbox
																				checked={selectedYears.includes(
																					year
																				)}
																				onChange={handleYearChange(
																					year
																				)}
																				color="primary"
																			/>
																		}
																		label={year.toString()}
																	/>
																)
															)}
															{errors.season && (
																<Typography
																	variant="body2"
																	color="error"
																>
																	{
																		errors
																			.season
																			.message
																	}
																</Typography>
															)}
														</div>
													</div>
												</BsCol>
											</BsRow>
											<BsRow class="mt-5">
												<BsCol class="col-md-12 text-center">
													<SaveAction
														title="Guardar"
														color="secondary"
														size="large"
														className="roundedButton ml-md-2"
														variant="contained"
														disabled={
															loadingQueryState
														}
														messageType="add"
														mutationLoading={
															loadingQueryState
														}
														success={
															successQueryState
														}
													/>
												</BsCol>
											</BsRow>
										</form>
									</BsCol>
								</BsRow>
								{errorState && (
									<Snackbar
										anchorOrigin={{
											vertical: 'bottom',
											horizontal: 'center',
										}}
										open={errorState}
										onClose={() => setErrorState(false)}
										autoHideDuration={4500}
										key={Math.random()}
									>
										<SnackBarsComponent
											variant="error"
											message="Unidad productiva ya registrada"
											handleClose={
												handleCloseSnackBarsComponent
											}
										/>
									</Snackbar>
								)}
							</CardContent>
						</Card>
					</BsCol>
				</BsRow>
			</BsContainer>
		</div>
	);
};
export default PuAdd;
